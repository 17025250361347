import { FeatureData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../../../components/View';
import Form from '../../../../../../containers/Espace/Form';
import params from '../../../../../../params/feature.json';
import TemplateEspace from '../../../../../../templates/espace';
import requireEspace, {
  EspaceProps,
} from '../../../../../../utils/requireEspace';
import { UserProps } from '../../../../../../utils/requireUser';

const PageFeaturesUpdate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId, id },
}) => {
  const model = new FeatureData({
    espaceId,
    params,
    collectionName: 'features',
  });

  if (!id) {
    return <div>La caractéristique n&apos;existe pas ou a été supprimé</div>;
  }

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form
          docId={id}
          itemPathnamePrefix={`/espaces/${espaceId}/params/features/`}
          model={model}
          name="feature"
          type="update"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageFeaturesUpdate);
